import React from 'react';

const Footer = () => <div className="footer">
  <div>
    &copy; 2019 Ken Kelly
  </div>
  <div>
    <a href="http://www.linkedin.com/in/kenhkelly" target="_blank" rel="noopener noreferrer" className="invert"><i className="icon-linkedin-icon" /></a>
    <a href="http://twitter.com/kenhkelly" target="_blank" rel="noopener noreferrer" className="invert"><i className="icon-twitter-icon" /></a>
    <a href="http://github.com/kenhkelly" target="_blank" rel="noopener noreferrer" className="invert"><i className="icon-github-icon" /></a>
    <a href="mailto:ken@kenhkelly.us" target="_blank" rel="noopener noreferrer" className="invert"><i className="icon-email-icon" /></a>
  </div>
</div>;

export default Footer;
