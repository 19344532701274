import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => <div className="header">
  <nav>
    <Link to="/">Ken Kelly</Link>
    <span />
    <Link to="/projects">Projects</Link>
    <Link to="/skills">Skills</Link>
    <a href="https://kenhkelly-resume.s3.amazonaws.com/Ken+Kelly+-+Software+Engineer+v15.pdf" target="_blank" rel="noopener noreferrer">Resume</a>
    <a href="mailto:hire@kenhkelly.us" target="_blank" rel="noopener noreferrer">Contact</a>
  </nav>
</div>;

export default Header;
