import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './pages/HomePage';
import ProjectsPage from './pages/ProjectsPage';
import SkillsPage from './pages/SkillsPage';
import Footer from './components/Footer';
import NotFoundPage from './pages/NotFoundPage';
import './styles/app.scss';

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Header />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/projects" component={ProjectsPage} />
        <Route exact path="/skills" component={SkillsPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
      <Footer />

    </Router>
  );
};

const ScrollToTop = withRouter((props) => {
  const { location: { pathname = '' } = {} } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
});

export default App;
